<script setup lang="ts">
import type { SidebarStep } from '@/types/registrationData';

defineOptions({
  name: 'CompleteRegistrationView',
});
const { $mopI18n, $cookie } = useNuxtApp();
const { initCompleteRegistration, initSalesAgents, stepDataRef, dataRef, isFormCompletedRef } = useRegistrationForm();
const route = useRoute();
const loadingRef = ref(true);
const showLoadingRef = ref(true);

onMounted(async () => {
  if (!route.params.id) {
    return;
  }

  await Promise.all([initCompleteRegistration(String(route.params.id)), initSalesAgents()]);

  const isDebugCookieEnabled = Boolean($cookie.get(COOKIE.DEBUG));
  const showForm = isDebugCookieEnabled || !isFormCompletedRef.value;
  if (!dataRef.value || !showForm) {
    return;
  }

  stepDataRef.value = {
    previousComponent: '',
    component: 'FormCompleteSummary',
    nextComponent: 'FormCompleteSectionAdditionalEntries',
    title: $mopI18n.t('form.summary.step.title'),
    subTitle: $mopI18n.t('form.summary.step.subtitle'),
  };

  loadingRef.value = false;
  setTimeout(() => {
    showLoadingRef.value = false;
  }, LOADING_DELAY);
});

const steps: SidebarStep[] = [
  {
    componentNames: ['FormCompleteSummary'],
    title: $mopI18n.t('form.complete_summary.step.title'),
  },
  {
    componentNames: ['FormCompleteSectionAdditionalEntries'],
    title: $mopI18n.t('form.additional_info.step.title'),
  },
];
</script>

<template>
  <div v-if="showLoadingRef" class="complete-registration-loading">
    <Ui2Loader size="lg" />
  </div>
  <div
    v-if="!loadingRef"
    :class="[
      'complete-registration',
      {
        'complete-registration--loading': showLoadingRef,
      },
    ]"
  >
    <FormSidebar :steps="steps" />
    <FormComplete class="form-register" />
  </div>
</template>

<style scoped lang="scss">
.complete-registration-loading {
  display: flex;
  height: 60vh;
  justify-content: center;
  align-items: center;
}
.complete-registration {
  opacity: 1;
  transition: opacity $animation-duration-long $animation-type-linear;
  padding: $space-96 $space-32 $space-64;
  display: flex;
  gap: $space-64;

  @include v2-apply-upto(mobile) {
    flex-direction: column;
    padding: 0 $space-16 $space-64;
  }
}

.complete-registration--loading {
  opacity: 0;
}

.form-register {
  flex-grow: 1;
}
</style>
